
.btn-custom {
  font-size: 0.8rem; /* Button font size */
  padding: 0.4rem 0.6rem;
  margin: 0.2rem;
  border-radius: 0.3rem;
}
.custom-width-input {
  font-size: 0.8rem; /* Button font size */
    padding: 0.4rem 0.6rem;
    margin: 0.2rem;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 400px;  /* Adjust the max-width as needed */
  }
  .table-container {
    width: 100%;
    margin: 1rem 0;
    overflow: hidden; /* Prevent horizontal scroll */
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: 0.8rem;
  }
  
  .responsive-table th,
  .responsive-table td {
    padding: 0.75rem 0.5rem;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .responsive-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .responsive-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  @media (max-width: 768px) {
    .responsive-table thead {
      display: none;
    }
  
    .responsive-table tr {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
    }
  
    .responsive-table td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      position: relative;
      text-align: right;
    }
  
    .responsive-table td::before {
      content: attr(data-label);
      font-weight: bold;
      text-align: left;
      color: #555;
    }
  
    .responsive-table td[data-label="Price"],
    .responsive-table td[data-label="Status"] {
      flex: 1 1 100%;
    }
  
    .responsive-table td[data-label="Rental Date"] {
      flex: 1 1 100%;
    }
  
    .responsive-table td {
      border: none;
    }
  }

  /* Ensure dropdowns within the table have consistent styling */
.responsive-table select {
  font-size: 0.8rem; /* Match the font size */
  padding: 0.4rem 0.6rem; /* Match the padding */
  border-radius: 0.3rem; /* Match the border-radius */
  border: 1px solid #ddd; /* Match the border style */
  background-color: #fff; /* Match the background color */
  color: #555; /* Ensure text color is consistent */
}

/* Ensure dropdowns are responsive */
@media (max-width: 768px) {
  .responsive-table select {
    font-size: 0.8rem; /* Maintain font size on smaller screens */
    padding: 0.5rem 1rem; /* Adjust padding for better fit */
    width: 100%; /* Ensure it takes full width of the cell */
  }
}
/* Ensure datetime-local inputs within the table have consistent styling */
.responsive-table input[type="datetime-local"] {
  font-size: 0.8rem; /* Match the font size */
  padding: 0.4rem 0.6rem; /* Match the padding */
  border-radius: 0.3rem; /* Match the border-radius */
  border: 1px solid #ddd; /* Match the border style */
  background-color: #fff; /* Match the background color */
  color: #555; /* Ensure text color is consistent */
  width: 100%; /* Ensure it takes full width of the cell */
  box-sizing: border-box; /* Include padding and border in the element’s total width and height */
}

/* Ensure datetime-local inputs are responsive */
@media (max-width: 768px) {
  .responsive-table input[type="datetime-local"] {
    font-size: 0.8rem; /* Maintain font size on smaller screens */
    padding: 0.5rem 1rem; /* Adjust padding for better fit */
  }
}
/* Ensure Typeahead component has consistent styling */
.custom-width-inputs {
  font-size: 0.8rem; /* Match the font size */
  padding: 0.4rem 0.6rem; /* Match the padding */
  border-radius: 0.3rem; /* Match the border-radius */
  border: 1px solid #ddd; /* Match the border style */
  background-color: #fff; /* Match the background color */
  color: #555; /* Ensure text color is consistent */
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box; /* Include padding and border in the element’s total width and height */
}

/* Ensure consistent styling for menu items */
.rbt-menu {
  font-size: 0.8rem; /* Match the font size */
}

.rbt-menu-item {
  padding: 0.4rem 0.6rem; /* Match the padding */
}
/* Ensure Card component styling is consistent */
.center-card-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically (if needed) */
  height: 100%; /* Ensure the container takes full height if needed */
  width: 100%; /* Full width to center horizontally */
  text-align: center; /* Center text inside the Card */
}
.user-card {
  width: 50%;
  justify-content: center; /* Center items horizontally */
  font-size: 0.8rem; /* Match the font size */
  border-radius: 0.3rem; /* Match the border-radius */
  border: 1px solid #ddd; /* Match the border style */
  background-color: #fff; /* Match the background color */
  color: #555; /* Ensure text color is consistent */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow for better visibility */
}
.detailplace{
  padding-left: 200px;
  padding-bottom: 10px;
  border: none; /* Match the border style */

}

.userdetail{
  background-color: rgb(222, 222, 222);
  border: none; /* Match the border style */
}

/* Adjust padding inside Card */
.user-card .card-body {
  padding: 1rem; /* Adjust padding as needed */
}
.typeahead-container {

  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */

  display: flex;
  justify-content: center; /* Center horizontally */
  width: 100%; /* Full width to accommodate centering */
  margin: 0 auto; /* Center the container if it has a fixed width */
}
/* RentItem.css */

/* Style for the Typeahead dropdown */
.typeahead-dropdown .rbt-menu {
  max-height: 1px; /* Set the height you need */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add border for better visibility */
}

/* Optional: Style for the dropdown items */
.typeahead-dropdown .rbt-menu > .rbt-menu-item {
  white-space: nowrap; /* Prevent text wrapping */
}
.scrollable-dropdown .rbt-menu {
  max-height: 20px; /* Adjust this height as needed */
  overflow-y: auto;
}


.custom-width-typeahead {
  width: 550px; /* Adjust the width as needed */
  
}
/* RentItem.css */

.availability-available {
  color: green;
  font-weight: bold;
}

.availability-not-available {
  color: red;
  font-weight: bold;
}

.availability-checking {
  color: orange;
  font-weight: bold;
}

.image-card {
  margin-left: 40px; /* Adjust the value as needed */
  margin-bottom: 40px;
}

