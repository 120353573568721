.center-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}
.center-form Form{
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border: 1px  solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
}
.center-form Form .form-control{
    margin-bottom: 20px;
    padding: 10px;
}
.error-message {
    margin-top: 5px; /* Add margin to create space between the field and error message */
  }
  
  .error-message-red {
    color: red;
    margin-top: 5px;
  }

  .input-group-text {
    cursor: pointer;
  }
  
  .input-group-text button {
    border: none;
    background: transparent;
  }
 