/* RentalCategoryDetails.css */
.rental-item {
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rental-item h2 {
    color: #343a40;
}

.rental-item p {
    margin: 0;
}

.table thead th {
    background-color: #343a40;
    color: #ffffff;
}

.table tbody tr:hover {
    background-color: #e9ecef;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
.status-alert {
    height: 10px;
    text-align: center;
    margin: 0 auto; /* Center the alert within its container */
   
    
  }
  

  
  /* RentalCategoryDetails.css */

.custom-alert {
    font-weight: bold;
    text-align: center;
  }
  
  .alert-completed {
    background-color: #d4edda; /* Light green background */
    border-color: #c3e6cb; /* Green border */
    color: #155724; /* Dark green text */
  }
  
  .alert-pending {
    background-color: #f8d7da; /* Light red background */
    border-color: #f5c6cb; /* Red border */
    color: #721c24; /* Dark red text */
  }
  
  .alert-default {
    background-color: #e2e3e5; /* Light gray background */
    border-color: #d6d6d6; /* Gray border */
    color: #6c757d; /* Gray text */
  }
  /* RentalCategoryDetails.css */
.form-select {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}
