.full-height-container {
    background-color: #dedede; /* Light grey background */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .bg-light{
    background-color: #ffffff; /* Light grey background */
   
  }
  