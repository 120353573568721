.center-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
  }
  
  .center-form Form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1000px; /* Adjust max-width as needed */
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .center-form Form .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .center-form Form .form-group label {
    margin-bottom: 5px;
  }
  
  .center-form Form .form-group input,
  .center-form Form .form-group textarea,
  .center-form Form .form-group select {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .error-message {
    margin-top: 5px;
  }
  
  .error-message-red {
    color: red;
    margin-top: 5px;
  }
  .items-container {
    margin: 20px;
  }
  
  .items-container h5 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .items-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .items-container li {
    margin-bottom: 20px;
  }
  
  .items-container p {
    margin: 5px 0;
  }
  
  .items-container img {
    width: 200px;
    height: auto;
  }
  /* Change the background color of pagination items to black */
.page-item .page-link {
  background-color: black;
  color: white; /* Optional: Change text color to white for better contrast */
  
}

/* Change the background color of the active pagination item */
.page-item.active .page-link {
  background-color: black;
  color: white; /* Optional: Change text color to white for better contrast */
}
