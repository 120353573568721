/* src/pages/LoginPage.css */
.image-container {
    background-color: black; /* Black background for the image container */
    padding: 10px; /* Optional padding around the image */
    display: flex; /* Center image horizontally */
    justify-content: center; /* Center image horizontally */
    border-radius: 15px; /* Rounded corners for the background */
  }
  
  .logo-image {
    max-width: 150px;
    height: auto;
  }
  .new-alert {
    margin-bottom: 10px;
    margin-left: 5px;
    width: 300px;
    text-align: center;
  }