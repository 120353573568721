/* Sidebar Styles */
.sidebar {
  position: relative;
  min-height: 100vh; /* Ensures full viewport height */
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed {
  width: 90px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.sidebar-logo {
  max-width: 150px;
  height: auto;
  padding-left: 10px;
  margin-bottom: 20px;
}

.sidebar-toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background-color: red; /* Temporary background to see if the button is there */
  color: white;
  z-index: 1000; /* Ensure it’s on top of other elements */
}

/* Navigation Links */
.sidenav {
  flex-grow: 1; /* Allows the sidebar to grow with content */
  padding-bottom: 10px;
  margin-right: 5px;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.nav-link {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 25px;
  margin-right: 20px;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;

}

.nav-link:hover {
  color: #17a2b8;
}

.nav-link i {
  margin-right: 10px;
  margin-left: auto; /* Aligns the caret icon to the right */

 

}

.dropdown-text {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
  

}

.dropdown-text i {
  margin-right: 10px;

}

.dropdown-container {
  padding-left: 5px;
  margin-bottom: 20px;
  color: rgb(185, 185, 185);
}

.dropdown-container .nav-link {
  font-size: 16px;
  margin-bottom: 15px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 80px;
  }

  .sidebar.collapsed {
    width: 60px;
  }

  .nav-link {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .dropdown-container .nav-link {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.search-input {
  width: 250px; /* Adjust width as needed */
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0c0c0c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
  opacity: 0.7;
  z-index: 9999; /* Ensure the button is on top of other elements */
}

.back-to-top:hover {
  opacity: 1;
}

.notification-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.notification-badge {
  position: absolute;
  top: 7px; /* Adjust as needed */
  left: -25px; /* Adjust as needed */
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 0.14em 0.7em;
  font-size: 0.75rem;
}

.notification-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: black; /* Default color for the icon */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.notification-icon:hover {
  color: black; /* Color remains the same on hover */
}