.my-component {
  height: 100vh; /* Full viewport height */
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  display: flex;
  flex: 1;
  background-color: rgb(222, 222, 222);
}

.sidebar {
  width: 20%; /* Use percentage for responsive width */
  max-width: 250px; /* Cap the width for larger screens */
  background-color: #333; /* Sidebar background color */
  color: #fff; /* Sidebar text color */
  flex-shrink: 0; /* Prevent sidebar from shrinking */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.content-area {
  flex: 1;
  padding: 2rem; /* Use rem for responsive padding */
  overflow-y: auto; /* Allow scrolling if content overflows */
  background-color: rgb(222, 222, 222);
}

/* Ensure the sidebar gets the class from Sidebar component */
.CDBSidebar {
  height: 100vh; /* Full height for the sidebar */
}

/* Responsive Font Sizes */
body {
  font-size: 16px; /* Base font size */
}

.sidebar,
.content-area {
  font-size: 1rem; /* Responsive text size */
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .sidebar {
    width: 25%; /* Adjust sidebar width for larger screens */
    max-width: 20px; /* Further cap width */
  }
  
  .content-area {
    padding: 1.5rem; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 30%; /* Adjust sidebar width for tablets */
    max-width: 180px; /* Limit width for smaller screens */
  }
  
  .content-area {
    padding: 1rem; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100px; /* Adjust sidebar width for mobile */
  }
  
  .content-area {
    padding: 0.5rem; /* Further reduce padding for mobile */
  }

  .app-container {
    flex-direction: column; /* Stack the elements vertically on small screens */
  }
}

/* Additional Styling for Elements Inside the Sidebar */
.sidebar h1,
.sidebar h2,
.sidebar h3,
.sidebar p {
  margin: 0.5rem 0; /* Use responsive margins */
  font-size: 1em; /* Ensure text scales */
}

/* Improve the sidebar toggle (if applicable) */
.sidebar-toggle {
  display: none; /* Hide on larger screens */
}

@media (max-width: 768px) {
  .sidebar-toggle {
    display: block; /* Show toggle button on smaller screens */
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }

  .sidebar {
    position: fixed; /* Make sidebar fixed on small screens */
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .sidebar.open {
    transform: translateX(0); /* Slide in the sidebar when open */
  }
}
