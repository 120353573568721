
  /* Analytic.css */
.dashboard .card-body {
  position: relative;
}

.dashboard .chart-container {
  position: relative;
  height: 400px; /* Adjust height as needed */
  width: 100%;
}

.dashboard .chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.dashboard {
  margin: 20px;
}

.fixed-size-chart {
  min-height: 400px;
  max-height: 400px;
}

.chart-container {
  height: 400px;
}
.analytic {
  padding: 20px;
}

.date-picker {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 0.25rem;
}

.analytics-card {
  margin-bottom: 20px;
}

.chart-card {
  margin-bottom: 20px;
}

.chart-container {
  margin-bottom: 20px;
}
.date-range-selector {
  
  padding-left: 400px;
  
  display: flex;
  justify-content: center;
}

.form-select {
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: #f8f9fa;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.form-select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  outline: none;
}
/* Custom styles for the DatePicker components */
.custom-date-picker {
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: #f8f9fa;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.custom-date-picker:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  outline: none;
}

.react-datepicker__triangle {
  display: none; /* Hide the default triangle */
}

.react-datepicker__header {
  background-color: #007bff;
  border: none;
  border-radius: 5px 5px 0 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #007bff;
  color: #ffffff;
}

.react-datepicker__day:hover {
  background-color: #e9ecef;
  border-radius: 50%;
}

.react-datepicker__month-container {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

